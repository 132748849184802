import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ptPT } from '@mui/material/locale';
import CssBaseline from '@mui/material/CssBaseline';
import AuthWorkspace from './components/AuthWorkspace/AuthWorkspace';
import MainWorkspace from './components/MainWorkspace/MainWorkspace';
import useThemeStore from './stores/themeStore';
import useWorkspaceStore from './stores/workspaceStore';
import BusyIndicator from './components/Shared/BusyIndicator';
import Toast from './components/Shared/Toast';
import PFFetchBase from './services/customFetch/PFFetchBase';
import refreshTokenInterceptor from './services/customFetch/refreshTokenInterceptor';
import addAccessTokenToHeaderInterceptor from './services/customFetch/addAccessTokenToHeaderInterceptor';
import UserAPI from './services/api/UserAPI';
import useToastStore from './stores/toastStore';
import PFEnvironment from './services/shared/PFEnvironment';
import useBusyIndicatorStore from './stores/busyIndicatorStore';

export default function App() {
  const { mode } = useThemeStore();
  const { isMainWorkspaceActive, activateAuthWorkspace } = useWorkspaceStore();
  const { busyIndicator } = useBusyIndicatorStore();
  const { toast } = useToastStore();
    
  const theme = React.useMemo(
    () => createTheme({ palette: { mode }}, ptPT), 
    [mode]
  );

  const handleLoggingOutEvent = async (event) => {
    try {
      console.log(`Event ${event.type}: (${event.detail.reasonId}) ${event.detail.reasonText}`);

      busyIndicator.show();

      await UserAPI.logout();
      activateAuthWorkspace();

      busyIndicator.hide();

      if (event.detail.reasonId === 1) {
        toast.showWarning('Credenciais inválidas ou expiradas');
      }      
    } catch (error) {
      busyIndicator.hide();
      PFEnvironment.dispatchSystemErrorEvent(error.message);
    }
  }

  const handleSystemErrorEvent = async (event) => {
    toast.showError(event.detail.message);
  }

  React.useEffect(() => {
      document.addEventListener('loggingOutEvent', handleLoggingOutEvent);
      document.addEventListener('systemErrorEvent', handleSystemErrorEvent);

      PFFetchBase.addBeforeInterceptor(addAccessTokenToHeaderInterceptor);

      PFFetchBase.addAfterInterceptor(refreshTokenInterceptor);

      return () => {
        document.removeEventListener('loggingOutEvent', handleLoggingOutEvent);
        document.removeEventListener('systemErrorEvent', handleSystemErrorEvent);
        PFFetchBase.clearAllInterceptors();
      }
    },
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BusyIndicator />
      <Toast />
      { isMainWorkspaceActive ? <MainWorkspace /> : <AuthWorkspace /> }
    </ThemeProvider>
  )
};