import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Drawer as MuiDrawer, List, Divider, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Constants from '../Shared/Constants';
import FeatureProvider from './FeatureProvider';
import FeatureItem from './FeatureItem';

const openedMixin = (theme) => ({
  width: Constants.featureSideBarExpandedWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  width: Constants.featureSideBarCollapsedWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' }) (
  ({ theme, open }) => ({
    width: Constants.featureSideBarExpandedWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function FeatureSideBar() {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [features] = React.useState(FeatureProvider.getAll);

  const handleSideBarToggle = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <Drawer variant="permanent" open={isExpanded}>
      <DrawerHeader>
        <IconButton onClick={handleSideBarToggle}>
          { isExpanded ? <ChevronLeft /> : <ChevronRight /> }
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {features.map((feature) => (
          <FeatureItem key={feature.title} feature={feature} isExpanded={open} />
        ))}
      </List>
    </Drawer>
  )
};
