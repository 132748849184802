import AccessTokenDecoder from "../shared/AccessTokenDecoder";
import PFStorage from "../shared/PFStorage";
import PFFetch from "../customFetch/PFFetch";

export default class UserAPI {
  static async login(credentials) {
    const body = JSON.stringify(credentials);
    const response = await PFFetch.post('/auth/login', body);
    
    const data = await response.json();

    if (response.ok) {
      console.info('Login successful:', data);
      PFStorage.setItem('accessToken', data.accessToken);
      PFStorage.setItem('refreshToken', data.refreshToken);
      return { success: true, response: data };
    }

    console.info(`Login failed! ${data.message}`);
    return { success: false };
  }

  static async changePassword(changePasswordDto) {
    const body = JSON.stringify(changePasswordDto);
    const response = await PFFetch.post('/auth/changepassword', body);
    
    if (response.ok) {
      console.info('Change Password operation successful');
      return { status: 'Success' };
    }
    
    if (response.status === 401) {
      console.info('Change Password operation unauthorized');
      return { status: 'Unauthorized' };
    }
    
    const data = await response.json();

    console.info(`Change Password operation failed: ${data.message}`);
    return { status: 'BadRequest', errorCode: data.errorCode, message: data.message };
  }

  static async logout() {
    await PFFetch.post('/auth/logout');
    PFStorage.removeItem('accessToken');
    PFStorage.removeItem('refreshToken');
  }

  static get userId() {
    const accessToken = PFStorage.getItem('accessToken');
    if (accessToken) {
      const accessTokenDecoder = new AccessTokenDecoder(accessToken);
      return accessTokenDecoder['userId'];
    } else {
      return '';
    }
  }

  static get socialName() {
    const accessToken = PFStorage.getItem('accessToken');
    if (accessToken) {
      const accessTokenDecoder = new AccessTokenDecoder(accessToken);
      return accessTokenDecoder['socialName'];
    } else {
      return '';
    }
  }
}