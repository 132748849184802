import PFFetch from "../customFetch/PFFetch";

export default class TransactionTypesAPI {
  static async getAll() {
    const response = await PFFetch.get('/transactiontypes');
    
    if (!response.ok) {
      throw new Error('Response not ok');
    }
    
    const data = await response.json();
    
    return data;
  }
}