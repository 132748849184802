import { Container, Box } from '@mui/material';
import useChangePasswordDialogStore from '../../stores/changePasswordDialogStore';
import ChangePasswordDialog from './ChangePasswordDialog';
import FeatureSideBar from './FeatureSideBar';
import MainWorkspaceAppBar from './MainWorkspaceAppBar';
import useMainPageStore from '../../stores/mainPageStore';

export default function MainWorkspace() {
  const { isChangePasswordDialogOpen } = useChangePasswordDialogStore();
  const { mainPage: MainPage } = useMainPageStore();

  return (
    <Box sx={{ display: 'flex' }}>
      <FeatureSideBar />

      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100vh' }}>
        <MainWorkspaceAppBar />

        <Container maxWidth={false} sx={{ my: 3, flex: 1, overflowY: 'auto' }}>
          <MainPage />
        </Container>
      </Box>

      {isChangePasswordDialogOpen && <ChangePasswordDialog />}
    </Box>
  )
};
