import * as React from 'react';
import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import useMainPageStore from '../../stores/mainPageStore';

export default function FeatureItem({ feature }) {
  const { setMainPage } = useMainPageStore();

  const { icon: FeatureIcon } = feature;

  const handleFeatureClick = (component) => {
    setMainPage(component);
  };

  return (
    feature.isDivider === true
      ? <Divider />
      : <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton onClick={() => handleFeatureClick(feature.component)} sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}>
            <ListItemIcon sx={{ minWidth: 0, mr: 3, justifyContent: 'center' }}>
              <FeatureIcon />
            </ListItemIcon>
            <ListItemText primary={feature.title} sx={{ opacity: 1 }} />
          </ListItemButton>
        </ListItem>
  )
}
