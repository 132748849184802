import * as React from 'react';
import { Box, Typography, Button, Checkbox } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton, Paper } from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import useBusyIndicatorStore from '../../../stores/busyIndicatorStore';
import TransactionTypesAPI from '../../../services/api/TransactionTypesAPI';
import PFEnvironment from '../../../services/shared/PFEnvironment';

export default function TransactionTypes() {
  const { busyIndicator } = useBusyIndicatorStore();
  const [rows, setRows] = React.useState([]);
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        busyIndicator.show();

        const responseData = await TransactionTypesAPI.getAll();

        busyIndicator.hide();

        setRows(responseData);
      } catch (error) {
        busyIndicator.hide();
        PFEnvironment.dispatchSystemErrorEvent(error.message);      
      }
    }

    fetchData();
  }, []);        
  
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  return (
    <Box>
      <Typography variant='h5'>
        Tipos de Transações
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" startIcon={<Add />} disableElevation sx={{ my: 3 }}>
          Novo
        </Button>
      </Box>
      <Paper sx={{ ml: 3, mb: 3 }} elevation={0} variant='outlined'>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '120px' }}></TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Positivo</TableCell>
                <TableCell>Recorrência</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id} onMouseEnter={() => setHoveredRow(row.id)} onMouseLeave={() => setHoveredRow(null)}>
                    <TableCell sx={{ opacity: hoveredRow === row.id ? 1 : 0.1 }}>
                        <>
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Delete />
                          </IconButton>
                        </>
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      <Checkbox checked={row.usuallyPositiveAmount} disabled />
                    </TableCell>
                    <TableCell>{row.recurrence.description}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
