import PFFetchBase from "./PFFetchBase";

export default class PFFetch {
  static async post(url, body = {}) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      }
    };

    const response = await PFFetchBase.fetch(url, {... options, body});

    return response;
  }

  static async get(url) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      }
    };

    const response = await PFFetchBase.fetch(url, {... options});

    return response;
  }
}