import InConstruction from "./Features/InConstruction";
import InConstruction2 from "./Features/InConstruction2";
import { Widgets, Inbox, Mail, AddModerator } from '@mui/icons-material';
import TransactionTypes from "./Features/TransactionTypes";

export default class FeatureProvider {
  static getAll() {
    return [
      {
        title: 'Opção 1',
        icon: Inbox,
        component: InConstruction
      },
      {
        title: 'Opção 2',
        icon: Mail,
        component: InConstruction2
      },
      {
        title: 'Opção 3',
        icon: AddModerator,
        component: InConstruction
      },
      {
        title: 'SettingsArea',
        isDivider: true
      },
      {
        title: 'Tipos de Transações',
        icon: Widgets,
        component: TransactionTypes
      },
      {
        title: 'Configuração 2',
        icon: AddModerator,
        component: InConstruction
      },
    ];
  }
}
